import React from "react"
import { createClient } from "@supabase/supabase-js"

const supabaseUrl = `${process.env.REACT_APP_SUPABASE_URL}`
const supabaseAnonKey = `${process.env.REACT_APP_SUPABASE_ANON_KEY}`
const supabaseTable = 'user'

  //headers: { 'x-my-custom-header': 'my-app-name' },
const options = {
  schema: "public",
  autoRefreshToken: true,
  persistSession: true,
  detectSessionInUrl: true
}
export const supabase = createClient(supabaseUrl, supabaseAnonKey, options)


export async function getAllUsers() {
  return supabase
    .from(supabaseTable)
    .select('*')

}

export async function insertUser(data) {
  return supabase.from(supabaseTable).insert(data)

}