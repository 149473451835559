import React from "react"
import { Field, useField, useFormikContext } from "formik"
import { ErrorMsgComp } from "./errorMsgComp"


export const MyRadioButtonGroupSimple = (props) => {
  const {
  } = useFormikContext()


  const [field, meta, helpers] = useField(props)


  return (
    <div className="col-span-6 my-8">
      <div>
        <legend
          className="text-lg md:text-2xl font-bold text-gray-900">{props.heading}
        </legend>
        {props.subheading ? <p className="text-gray-600 md:text-lg text-sm">{props.subheading}</p> : ""}

        <ErrorMsgComp name={field.name} />
      </div>


      <div role="group" className="mt-4 space-y-4">
        {props.elements.map((elmt) => (

          <label key={`${elmt.value}+${elmt.id}`} htmlFor={elmt.value} className="flex items-center">
            <Field id={elmt.value} type="radio" {...props} value={elmt.value} />
            <div
              className="text-black font-bold ml-4 flex-col md:text-lg text-md">{elmt.name}
              {elmt.description ? <div  className="text-black font-normal md:text-md text-sm">{elmt.description}</div> : ""}
              {elmt.price ? <div className="text-black italic">{elmt.price}</div> : ""}
            </div>
          </label>
        ))}


      </div>
    </div>
  )
}