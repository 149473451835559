import React from "react"
import { ErrorMsgComp } from "./errorMsgComp"


const InputWithLabel = (props) => {

  const {
    field,
    id,
    placeholder,
    label,
    styling,
    hint,
    styling_label,
    lable_on_bottom
  } = props;

  return (
    <div className={styling}>

      <div className="flex">
        {lable_on_bottom ? '' :
          <>
            <label htmlFor={id} className={`${styling_label}`}>
              {label}
            </label>
            {hint ?
              <span className="text-sm text-gray-500">
                {hint}
              </span> : ''}
          </>
        }


      </div>

      <input
        type="text"
        id={id}
        name={id}
        {...field}
        {...props}
        placeholder={placeholder}
        className="px-4 py-2 mt-2 text-caffeinity-green-dark border-caffeinity-green-dark border-2"
      />

      {lable_on_bottom ? <label htmlFor={id}
                                className={`${styling_label}`}>
        {label}
      </label> : ''}

      <ErrorMsgComp name={props.field.name}/>

    </div>
  );
}

export default InputWithLabel;