import React, { Component } from "react"

import { Field, Form, Formik } from "formik"

import SeoComponent from "../components/seo"
import Layout from "../components/layout"
import { insertUser, supabase } from "../services/supabaseClient"
import { StaticImage } from "gatsby-plugin-image"
import HeroComponent from "../components/heroComponent"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import InputWithLabel from "../components/questionaire/inputWithLabel"
import { MyRadioButtonGroupSimple } from "../components/questionaire/myRadioButtonGroupSimple"

let yup = require("yup")

const brewMethods = [
  {
    id: 1,
    name: "Vollautomat",
    value: "VOLLAUTOMAT"

  },
  {
    id: 2,
    name: "Siebträger",
    value: "SIEBTRAEGER"

  },
  {
    id: 3,
    name: "Filter / V60 / Chemex",
    value: "FILTER"

  },
  {
    id: 4,
    name: "French Press",
    value: "FRENCH_PRESS"

  },
  {
    id: 5,
    name: "Cold Brew",
    value: "COLD_BREW"

  },
  {
    id: 6,
    name: "Bialetti / Mokka",
    value: "MOKKA"

  }
]
const experience = [
  {
    id: 1,
    value: "NEULING",
    name: "Neuling",
    description: "Ich möchte mit etwas einfachem starten"

  },
  {
    id: 2,
    value: "INTERMEDIATE",
    name: "Intermediate",
    description: " Ich kaufe premium Kaffee aus dem Supermarkt"

  },
  {
    id: 3,
    value: "FORTGESCHRITTEN",
    name: "Fortgeschritten",
    description: "Ich kaufe Kaffee von Speciality Coffee shops"

  },
  {
    id: 4,
    value: "NERD",
    name: "Ich bin ein absoluter Kaffee Nerd",
    description: "Ich kenne den Unterschied zwischen Cattura und Bourbon und cuppe am liebsten neue Kaffees"

  }
]
const taste = [
  {
    id: 1,
    name: "Klassischer Kaffee",
    value: "CLASSIC",
    description: "Bei klassischem Kaffee geht es vor allem um die bekannte nostalgische Idee hinter Kaffee - nur besser! Diese Kaffees sind ausbalanciert, vollmundig und leicht zu trinken."

  },
  {
    id: 2,
    name: "Ein Hauch Abwechslung",
    value: "MODERN",
    description: "Das sind Kaffees mit einem kleinen Hauch von etwas speziellem, die trotzdem den großartigen und traditionellen Kaffeegeschmack transportieren, aber mit dem gewissen Etwas, das du so nicht erwarten würdest."

  },
  {
    id: 3,
    name: "Überraschend & Unkonventionell",
    value: "UNCONVENTIONAL",
    description: "Überraschende und unkonventionelle Kaffees sind etwas leichter geröstet. Sie haben eher den originalen Geschmack der Kaffeefrucht selbst und besitzen oft mehr Säure. Durch die besondere Verarbeitung tritt der Geschmack der Kaffeefrucht in den Vordergrund."

  },
  {
    id: 4,
    name: "Caffeinity soll entscheiden.",
    value: "CAFFEINITY_CHOICE",
    description: "Wir treffen die Auswahl basierend auf deinen anderen Antworten."

  }
]
const drinkHabits = [
  {
    id: 1,
    value: "BLACK",
    name: "Ich trinke ihn nur schwarz"

  },
  {
    id: 2,
    value: "MILK",
    name: "Milch oder Schlagobers"

  },
  {
    id: 3,
    value: "SIRUP",
    name: "Sirup mit Geschmack"

  },
  {
    id: 4,
    value: "MILK_BLEND",
    name: "Milchersatz (Hafermilch, Mandelmilch, Sojamilch, ..)"

  },
  {
    id: 5,
    value: "SUGGAR",
    name: "Zucker oder Süßungsmittel"

  },
  {
    id: 6,
    value: "COMBINATION",
    name: "Eine Kombination aus mehreren oben genannten"

  }
]
const wholeBean = [
  {
    id: 1,
    value: "True",
    name: "Ganze Bohne"

  },
  {
    id: 2,
    value: "False",
    name: "Gemahlener Kaffee"
  }
]
const decaff = [
  {
    id: 1,
    name: "Normal",
    value: "NORMAL",
    description: "Ich möchte nur koffeinhaltige Kaffeevorschläge bekommen."

  },
  {
    id: 2,
    name: "Entkoffeiniert",
    value: "DECAFF",
    description: "Ich möchte nur entkoffeinierte Kaffeevorschläge bekommen."
  }
]
const subscriptionType = [
  {
    id: 1,
    name: "Bloß mal Testen ",
    value: "1-250",
    description: "Einmalig 250g premium Kaffee mit Caffeinity Zubereitungsmaterial, inkl. Verpackung & Versand",
    price: "19,90€"
  },
  {
    id: 2,
    name: "Alle guten Dinge sind drei ",
    value: "3-250",
    description: "3 Monate lang 250g premium Kaffee mit Caffeinity Zubereitungsmaterial, inkl. Verpackung & Versand",
    price: "18,90€"
  },
  {
    id: 3,
    name: "Ich will Caffeine ",
    value: "6-250",
    description: "6 Monate lang 250g premium Kaffee mit Caffeinity Zubereitungsmaterial, inkl. Verpackung & Versand",
    price: "18,50€"
  }
]


class TastePage extends Component {

  caffeinityValidationSchema = yup.object().shape(
    {
      email: yup.string().required("Dieses Feld ist ein Pflichtfeld!").email("Bitte geben Sie eine gültige E-Mail ein zB: name@gmail.com"),
      preperationMethod: yup.string().required("Dieses Feld ist ein Pflichtfeld!"),
      experience: yup.string().required("Dieses Feld ist ein Pflichtfeld!"),
      drinkHabits: yup.string().required("Dieses Feld ist ein Pflichtfeld!"),
      taste: yup.string().required("Dieses Feld ist ein Pflichtfeld!"),
      wholeBean: yup.string().required("Dieses Feld ist ein Pflichtfeld!"),
      decaff: yup.string().required("Dieses Feld ist ein Pflichtfeld!"),
      subscriptionType: yup.string().required("Dieses Feld ist ein Pflichtfeld!")
    }
  )

  constructor(props) {
    super(props)
    supabase.auth.session()
    //Amplify.configure(awsconfig)

  }


  render() {
    return (
      <Layout>
        <SeoComponent title="Geschmacksprofil" />

        <HeroComponent header="Geschmacksprofil" subheader="In den nachfolgenden Schritten erstellst du dein Geschmacksprofil! Anhand deiner Auswahl wählen wir die am
              Besten passenden Kaffeebohnen für dich aus und senden sie dir zu. ">
          <StaticImage src="../images/nathan-dumlao-XOhI_kW_TaM-unsplash.jpg"
                       alt="Caffeinity title image coffee beans"
                       className="w-full h-96 object-center object-cover"

          />
        </HeroComponent>

        <div className="px-4 max-w-4xl mx-auto">
          <Formik
            initialValues={{
              email: "",
              preperationMethod: "",
              experience: "",
              drinkHabits: "",
              taste: "",
              wholeBean: "",
              decaff: "",
              subscriptionType: ""
            }}
            validationSchema={this.caffeinityValidationSchema}
            onSubmit={async (values, actions) => {

              let newUser = {
                preparation_method: values.preperationMethod,
                experience: values.experience,
                drinking_habit: values.drinkHabits,
                taste: values.taste,
                whole_bean: values.wholeBean,
                decaff: values.decaff,
                subscription_type: values.subscriptionType,
                email: values.email
              }

              insertUser(newUser)
                .then(r => {
                  if (r.error) {
                    toast.error("Ups..! Leider ist etwas schief gegangen. Bitte schreibe uns auf caffeinity.vienne@gmail.com.", { position: toast.POSITION.TOP_RIGHT })
                    return
                  }

                  if (newUser.subscription_type === "1-250") {
                    window.location.href = "https://buy.stripe.com/eVa2bc8FK3zKbqE8ww"

                  } else if (newUser.subscription_type === "3-250") {
                    window.location.href = "https://buy.stripe.com/28o8zAbRWb2c3YcaEF"

                  } else {
                    window.location.href = "https://buy.stripe.com/5kAdTU4pu1rC7aobIL"
                  }
                })
                .catch(e => {
                    console.log("ERROR " + JSON.stringify(e))
                    toast.error("Ups..! Leider ist etwas schief gegangen. Bitte schreibe uns auf caffeinity.vienne@gmail.com.", { position: toast.POSITION.TOP_RIGHT })
                  }
                )

              actions.setSubmitting(false)


            }}
          >
            {(values) => (
              <Form>

                <Field name="email"
                       component={InputWithLabel}
                       label="Deine E-Mail Adresse"
                       placeholder="caffeinity.vienna@gmail.com"
                       styling="col-span-6 my-8 flex flex-col"
                       styling_label="text-2xl font-bold text-gray-900"
                       value={values.email}
                />

                <MyRadioButtonGroupSimple name="preperationMethod"
                                          heading="Womit bereitest du normalerweise zu Hause deinen Kaffee zu? "
                                          elements={brewMethods} />
                <MyRadioButtonGroupSimple name="experience"
                                          heading="Wie würdest du deine Erfahrung mit Kaffee einschätzen?"
                                          elements={experience} />
                <MyRadioButtonGroupSimple name="drinkHabits" heading="Wie trinkst du deinen Kaffee üblicherweise?"
                                          subheading="Info: Milch oder Zucker kann den Geschmack des Kaffees verändern, weswegen andere Röstungen für Milchgetränke teilweise besser geeignet sind. Wir schlagen dir nur Kaffee vor welcher Hand in Hand mit deinen Vorlieben geht."
                                          elements={drinkHabits} />

                <MyRadioButtonGroupSimple name="taste" heading="Wonach soll dein Kaffee schmecken?"
                                          subheading="Info: Geschmack ist ein subjektives Ding, während kein Kaffee den wir im Sortiment haben zusätzliche Geschmacksstoffe enthält, können sie trotzdem ganz unterschiedlich schmecken. Je nachdem woher die Kaffebohnen stammen, wie sie verarbeitet und geröstet werden."
                                          elements={taste} />

                <MyRadioButtonGroupSimple name="wholeBean"
                                          heading="Möchtest du ganze Bohnen, gemahlene Bohnen oder beides kaufen?"
                                          subheading="Info: Wenn du eine wirklich gute Tasse Kaffee machen möchtest solltest du mit ganzen Bohnen starten und diese zu Hause mit einer Mühle firsch mahlen. Es ist wirklich sehr einfach und das Ergebnis wird dich verblüffen."
                                          elements={wholeBean} />

                <MyRadioButtonGroupSimple name="decaff" heading="Möchtest du normalen oder entkoffeinierten Kaffee?"
                                          subheading=""
                                          elements={decaff} />

                <MyRadioButtonGroupSimple name="subscriptionType"
                                          heading="Wähle hier deine gewünschte Option aus und klicke auf 'Weiter'. Alle Preise inklusive Verpackung und Versand nach Österreich."
                                          subheading=""
                                          elements={subscriptionType} />


                {<button type="submit" className="btn-primary m-auto mb-12">Zahlungspflichtig bestellen</button>}
              </Form>
            )}
          </Formik>


        </div>
      </Layout>
    )
  }
}

export default TastePage