import {ErrorMessage} from "formik";
import React from "react";

export const ErrorMsgComp = ({name}) => {
  return (
    <ErrorMessage
      id={name}
      name={name}
      component="div"
      className="field-error"
    />
  )
}